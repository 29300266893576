import { Controller } from "@hotwired/stimulus"

const passwordList = {
  message1: 'JODGLXV',
  message2: 'SURFHOOD',
  message3: 'FXU',
  message4: 'PHPLQL',
  sample: 'JODGLXV'
}

export default class extends Controller {
  static targets = ['phrase', 'errorMessage', "novel"]

  connect() {
  }

  showMessage({params}) {
    const validPassPhrase = passwordList[params.page]

    if (this.phraseTarget.value === validPassPhrase) {
      this.errorMessageTarget.textContent = ''
      window.scroll({ top: 0, behavior: 'smooth' })
      this.novelTarget.src = `./${params.page}_body.html`
    } else {
      this.errorMessageTarget.textContent = 'パスワードが間違っています'
    }
  }
}
